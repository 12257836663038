import React from 'react';
import styled from 'styled-components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import {
  Container,
  H1,
  H3,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import { Seo } from '../components';
import { secondary } from '../styles/colors';
import { NAME } from 'config';

const Contacts: React.FC = React.memo(() => {
  const { isMobile } = useQuery();

  return (
    <>
      <Seo title={`Contacts | ${NAME}`} />
      <Background>
        <Container>
          <ContentWrapper>
            <H1 margin={isMobile ? '0 0 1.5rem' : '0 0 3rem'}>Contacts</H1>
            <H3Styled>Company</H3Styled>
            <RegularStyled>InnoDiets UAB</RegularStyled>
            <H3Styled>Company code</H3Styled>
            <RegularStyled>305493571</RegularStyled>
            <H3Styled>Address of registration</H3Styled>
            <RegularStyled>
              Tarpupio g. 29, Mamavys, LT-21169 Trakų r.
            </RegularStyled>
            <H3Styled>Address</H3Styled>
            <RegularStyled>
              Antakalnio str. 17, LT-10312 Vilnius, Lithuania
            </RegularStyled>
            <H3Styled>Email</H3Styled>
            <RegularStyled>
              <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
            </RegularStyled>
          </ContentWrapper>
        </Container>
      </Background>
    </>
  );
});

Contacts.displayName = 'Contacts';

export default Contacts;

const H3Styled = styled(H3)`
  margin: 0 0 0.5rem;
`;

const RegularStyled = styled(Regular)`
  margin: 0 0 2.5rem;

  :last-child {
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  max-width: 22rem;
  width: 100%;
  margin: 0 auto;

  @media ${mobile} {
    max-width: none;
  }
`;

const Link = styled.a`
  color: ${secondary} !important;

  :hover {
    text-decoration: underline;
  }
`;

const Background = styled.div`
  margin: 3rem 0 0;
  min-height: calc(100vh - 13rem);

  @media ${mobile} {
    margin: 1.5rem 0 0;
  }
`;
